import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { CalendarEventCategories } from '../models'

const DEFAULT_CATEGORIES: CalendarEventCategories = {
  meeting: {
    id: 'meeting',
    name: 'Meeting',
    color: 'blue',
    icon: 'UserGroupIcon',
    keywords: [
      'meeting',
      'conference',
      'assembly',
      'gathering',
      'symposium',
      'roundtable',
      'forum',
      'discussion',
      'huddle',
      'summit',
      'conclave',
      'consultation',
      'convention',
      'briefing',
      'presentation',
      'demo',
      'demonstration',
      'lecture',
      'showcase',
      'exhibition',
      'display',
      'seminar',
      'talk',
      'webinar',
      'pitch',
      'portfolio',
      'session',
    ],
  },
  focusedWork: {
    id: 'focusedWork',
    name: 'Focused Work',
    color: 'teal',
    icon: 'EyeIcon',
    keywords: [
      'focused work',
      'deep work',
      'concentration time',
      'uninterrupted work',
      'productivity block',
      'focus session',
      'intensive work',
      'work block',
      'quiet time',
      'solo work',
      'individual work',
      'personal work time',
      'dedicated work',
      'work sprint',
      'focus period',
      'intense work session',
      'productivity session',
      'work focus',
      'concentrated effort',
      'undisturbed work',
    ],
  },
  dailyPlanning: {
    id: 'dailyPlanning',
    name: 'Daily Planning',
    color: 'indigo',
    icon: 'ClipboardIcon',
    keywords: ['plan', 'planning'],
  },
  creativeThinking: {
    id: 'creativeThinking',
    name: 'Creative Thinking',
    color: 'orange',
    icon: 'LightBulbIcon',
    keywords: ['creative', 'creativity', 'brainstorm', 'brainstorming', 'ideation', 'strategic', 'thinking'],
  },
  workTask: {
    id: 'workTask',
    name: 'Work Task',
    color: 'amber',
    icon: 'BriefcaseIcon',
    keywords: [
      'task',
      'assignment',
      'project',
      'work',
      'job',
      'duty',
      'responsibility',
      'endeavor',
      'to-do',
      'objective',
      'goal',
      'undertaking',
      'operation',
      'preparation',
    ],
  },
  teamBuilding: {
    id: 'teamBuilding',
    name: 'Team Building',
    color: 'green',
    icon: 'UserGroupIcon',
    keywords: [
      'team building',
      'team exercise',
      'team activity',
      'group workshop',
      'team retreat',
      'team bonding',
      'group exercise',
      'teamwork activity',
      'collaboration event',
      'team development',
      'team outing',
      'staff retreat',
      'corporate retreat',
      'team session',
      'group bonding',
      'team workshop',
      'group activity',
      'team-building session',
      'team day out',
      'team challenge',
    ],
  },
  networking: {
    id: 'networking',
    name: 'Networking',
    color: 'emerald',
    icon: 'UsersIcon',
    keywords: [
      'networking',
      'mixer',
      'meet and greet',
      'socializing',
      'connecting',
      'interaction',
      'business meeting',
      'professional gathering',
      'industry event',
      'career fair',
      'alumni event',
    ],
  },
  exercise: {
    id: 'exercise',
    name: 'Exercise',
    color: 'green',
    icon: 'BoltIcon',
    keywords: [
      'exercise',
      'workout',
      'training',
      'fitness',
      'gym',
      'running',
      'jogging',
      'cycling',
      'yoga',
      'pilates',
      'aerobics',
      'weightlifting',
      'sports',
      'swimming',
    ],
  },
  mealBreak: {
    id: 'mealBreak',
    name: 'Meal Break',
    color: 'orange',
    icon: 'CakeIcon',
    keywords: [
      'meal',
      'lunch',
      'breakfast',
      'dinner',
      'snack',
      'brunch',
      'coffee break',
      'tea time',
      'lunch break',
      'dining',
      'food',
      'eating',
      'picnic',
      'refreshment',
    ],
  },
  medicalAppointment: {
    id: 'medicalAppointment',
    name: 'Medical Appointment',
    color: 'pink',
    icon: 'HeartIcon',
    keywords: [
      'doctor',
      'dentist',
      'clinic',
      'hospital',
      'medical',
      'health check',
      'appointment',
      'consultation',
      'treatment',
      'therapy',
      'check-up',
      'examination',
      'surgery',
    ],
  },
  familyTime: {
    id: 'familyTime',
    name: 'Family Time',
    color: 'indigo',
    icon: 'HomeIcon',
    keywords: [
      'family',
      'home time',
      'family meal',
      'family outing',
      'quality time',
      'family gathering',
      'together',
      'reunion',
      'family event',
      'playtime',
      'family night',
      'family activity',
    ],
  },
  relaxation: {
    id: 'relaxation',
    name: 'Mindfulness & Relaxation',
    color: 'cyan',
    icon: 'SunIcon',
    keywords: [
      'relax',
      'relaxation',
      'rest',
      'chill',
      'unwind',
      'leisure',
      'downtime',
      'lounge',
      'repose',
      'lazy time',
      'break time',
      'siesta',
      'nap',
      'recreation',
      'meditation',
      'meditate',
      'mindfulness',
      'zen',
      'quiet time',
      'tranquility',
      'peace',
      'contemplation',
      'breathing exercise',
      'guided relaxation',
    ],
  },
  social: {
    id: 'social',
    name: 'Social Event',
    color: 'purple',
    icon: 'ChatBubbleBottomCenterIcon',
    keywords: [
      'party',
      'gathering',
      'meetup',
      'social',
      'get-together',
      'celebration',
      'dinner',
      'hangout',
      'outing',
      'event',
      'festival',
      'reunion',
      'barbecue',
      'picnic',
      'soiree',
    ],
  },
  hobby: {
    id: 'hobby',
    name: 'Hobby',
    color: 'fuchsia',
    icon: 'MusicalNoteIcon',
    keywords: [
      'hobby',
      'craft',
      'diy',
      'painting',
      'drawing',
      'gardening',
      'knitting',
      'photography',
      'writing',
      'sculpting',
      'sewing',
      'baking',
      'cooking',
      'woodworking',
      'model building',
    ],
  },
  travel: {
    id: 'travel',
    name: 'Travel',
    color: 'rose',
    icon: 'GlobeEuropeAfricaIcon',
    keywords: [
      'travel',
      'trip',
      'journey',
      'tour',
      'excursion',
      'expedition',
      'holiday',
      'vacation',
      'sightseeing',
      'road trip',
      'flight',
      'cruise',
      'adventure',
      'getaway',
    ],
  },
  personalDevelopment: {
    id: 'personalDevelopment',
    name: 'Personal Development',
    color: 'violet',
    icon: 'AcademicCapIcon',
    keywords: [
      'training',
      'course',
      'seminar',
      'workshop',
      'webinar',
      'learning',
      'skill',
      'education',
      'certificate',
      'class',
      'lecture',
      'study',
      'tutorial',
      'self-improvement',
      'personal project',
      'personal projects',
    ],
  },
  errands: {
    id: 'errands',
    name: 'Errands',
    color: 'amber',
    icon: 'ShoppingBagIcon',
    keywords: [
      'errand',
      'grocery',
      'shopping',
      'market',
      'bank',
      'post office',
      'laundry',
      'pharmacy',
      'dry cleaning',
      'appointment',
      'bills',
      'admin',
      'chores',
      'supplies',
    ],
  },
  chores: {
    id: 'chores',
    name: 'Chores',
    color: 'green',
    icon: 'ShoppingBagIcon',
    keywords: [
      'chores',
      'cleaning',
      'laundry',
      'dishes',
      'tidying',
      'vacuuming',
      'gardening',
      'mowing',
      'organizing',
      'decluttering',
      'washing',
      'maintenance',
      'housework',
      'repairs',
    ],
  },
  unspecified: {
    id: 'unspecified',
    name: 'Unspecified',
    color: 'gray',
    icon: 'QuestionMarkCircleIcon',
    keywords: [],
  },
}

const atom = atomWithStorage('calendarEventCategories', DEFAULT_CATEGORIES)

export const useCalendarEventCategories = () => useAtom(atom)
