import React from 'react'

import { ICalIntegrationSection } from './components/iCalIntegrationSection/ICalIntegrationSection'

export const SettingsCalendars = () => {
  return (
    <div>
      <ICalIntegrationSection />
    </div>
  )
}
